"use strict";

import Handlebars from 'handlebars';
import _each from 'lodash/each';

import popinTemplate from './template.html';

const popinCompiled = Handlebars.compile(popinTemplate);

let vp = null;

export default function create_popin(content,closeCallback){
	
	
	const popin = popinCompiled({content:content});
	const popinContainer = document.createElement('div');
	popinContainer.innerHTML = popin;

	_each(popinContainer.querySelectorAll('[data-click="popin-close"]'),(item) => item.addEventListener('click',() => {
		if(closeCallback){
			if(closeCallback()){
				document.body.removeChild(popinContainer);
			}
		}else{
			document.body.removeChild(popinContainer);
		}
	}));
	document.body.appendChild(popinContainer);
	return popinContainer;
}