import request from 'superagent';
import legacyIESupport from 'superagent-legacyIESupport';

export default function embed(base,container){
   
    
    if(!container) return;



    const head = document.querySelector('head');

    _createCSSLink("https://fonts.googleapis.com/css?family=Ubuntu+Condensed",head);
    _createCSSLink(base+"/front/bundle.css",head);

    //href="https://fonts.googleapis.com/css?family=Ubuntu+Condensed" 


    request(base+'/api/embed').use(legacyIESupport).end((err,res)=>{ 
        console.log(err,res);
        if(res){
            container.innerHTML = res.text;
        }else if(err){
            container.innerHTML = err.rawResponse;
        }
    });

    function _createCSSLink(href,head){
        const cssLink = document.createElement('link');
        cssLink.setAttribute('rel','stylesheet');
        cssLink.setAttribute('href',href);
        head.appendChild(cssLink);
    }
}