"use strict";

import Handlebars from 'handlebars';
import popin from './popin';
import ajaxForm from './ajaxForm';
import embed from './embed';
import districtrequestFormTemplate from './district-request-form.html';
import districtrequestFormSucesssTemplate from './district-request-form-success.html';
import formErrorsTemplate from './form-errors.html';
//import _reduce from 'lodash/reduce';

const districtrequestFormCompiled = Handlebars.compile(districtrequestFormTemplate);
const districtrequestFormSucesssCompiled = Handlebars.compile(districtrequestFormSucesssTemplate);
const formErrorsCompiled = Handlebars.compile(formErrorsTemplate);

const container = document.querySelector('.dialab-container');
const base = (container?container.getAttribute('data-path'):'') || '';

const api = {
	'front.api.request':base+"/api/district/meeting/request"
};



function open_request(district){

	var success = false;

	const requestPopin = popin(districtrequestFormCompiled({
		url:api['front.api.request'],
		district:district 
	}),function closePopin(){
		if(success){
			window.location.reload();
			return false;
		}
		return true;
	});

	const formContainer = requestPopin.querySelector(".rzdl-district-request-form");
	const form = formContainer.querySelector("form");
	const errors = formContainer.querySelector(".rzdl-errors");
	

	ajaxForm(form,
	() => {
		const formSubmitButton = formContainer.querySelector('.rzdl-button');
		formSubmitButton.setAttribute('disabled','disabled');
	},
	(result)=>{
		formContainer.innerHTML = districtrequestFormSucesssCompiled({message:result});
		success = true;
	},(err,result) => {
		if(result && result.errors){
			errors.innerHTML = formErrorsCompiled(result);
		}
	});
}

window.openRequest = open_request;

embed(base,container);