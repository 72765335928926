import request from 'superagent';
import legacyIESupport from 'superagent-legacyIESupport';
import _reduce from 'lodash/reduce';

export default function send_ajax_form(form,init,success,error){
	form.addEventListener("submit",function(event){
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();
		if(init)init();

		request.post(form.getAttribute('action'))
		//.use(legacyIESupport)
		.type('form')
		.send(
			_reduce(form.querySelectorAll("input,textarea"),(result,input) => {
				result[input.getAttribute("name")] = input.value;
				return result;
			},{})
		)
		.end((err,res) =>{
			console.log(err,res);
			if(err){
				if(err){
					if(res && res.type == "application/json"){
						error(err,res?JSON.parse(res.text):null);
					}else{
						error(err,res?res.text:null);
					}
				}
			}else{
				if(success){
					if(res.type == "application/json"){
						success(JSON.parse(res.text));
					}else{
						success(res.text);
					}
				}
			}	
		});
		return false;
	});
}